'use client';

import { RotatingLines } from 'react-loader-spinner';

export type AppLoadingComponentProps = {};

export default function AppLoadingComponent({}: AppLoadingComponentProps) {
    return (
        <>
            <div className="flex justify-center bg-white bg-opacity-50 items-center fixed top-0 bottom-0 left-0 right-0">
                <RotatingLines
                    visible={true}
                    // height="96"
                    width="96"
                    strokeWidth="3"
                    strokeColor="#9451A0"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                />
            </div>
        </>
    );
}
